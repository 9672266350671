<template>
  <div>
    <l-geo-json
      :geojson="data"
      :options="options"
      :options-style="styleOptions"
    ></l-geo-json>
  </div>
</template>
<script>
import { LGeoJson } from "vue2-leaflet";
export default {
  components: { LGeoJson },
  props: {
    data: {
      type: Object,
      required: true,
    },
    bounds: {
      required: true,
    },
    styleOptions: {
      type: Function,
      default: () => {},
    },
    pointToLayer: {
      type: Function,
      default: () => {},
    },
    onEachFeature: {
      type: Function,
      default: () => {},
    },
    filter: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      showError: false,
      loading: true,
    };
  },
  computed: {
    zoom() {
      return this.$store.state.zoom;
    },
    options() {
      return {
        onEachFeature: this.onEachFeature,
        pointToLayer: this.pointToLayer,
        filter: this.filter,
      };
    },
  },
};
</script>
